import React from "react"
import Hero from "../components/hero"

import Layout from "../components/layout"
import ScrollInd from "../components/scrolldot"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <ScrollInd />
    <Hero />
  </Layout>
)

export default IndexPage
