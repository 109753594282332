import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import { gsap, TweenMax, TimelineLite, Power3 } from "gsap"

//import assets
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
import PhoneImg from "../images/hero-img/herophone.png"

const Hero = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  // //gsap anim
  // let hero = useRef(null)
  // let image = useRef(null)
  // let content1 = useRef(null)
  // let content2 = useRef(null)
  // let content3 = useRef(null)
  // let content4 = useRef(null)
  // let button = useRef(null)

  // let tl = new TimelineLite()

  // useEffect(() => {
  //   //image var
  //   const phoneImage = image.firstElementChild

  //   //content var
  //   const headlineFirst = content1
  //   const headlineSecond = content2
  //   const headlineThird = content3
  //   const headlineFourth = content4
  //   const headlineButton = button

  //   TweenMax.to(hero, 0, { css: { visibility: "visible" } })

  //   //image anim
  //   tl.from(phoneImage, 1.2, {
  //     y: 1280,
  //     ease: Power3.easeOut,
  //   })

  //   //content anim
  //   gsap.from([headlineFirst, headlineSecond, headlineThird, headlineFourth], {
  //     opacity: 0,
  //     duration: 1.5,
  //     y: -50,
  //     ease: Power3.easeOut,
  //   })
  //   gsap.from(headlineButton, {
  //     opacity: 0,
  //     duration: 1.5,
  //     y: 100,
  //     ease: Power3.easeOut,
  //   })
  // })

  return (
    <HeroSection>
      <HeroContainer>
        <TextContainer>
          <TextH1>Made</TextH1>
          <br />
          <TextH1>Personal</TextH1>
          <br />
          <TextH1>Accounting</TextH1>
          <br />
          <TextH2>EASY!</TextH2>
          <HeroBtnWrapper>
            <HeroBtn to="/signup" onMouseEnter={onHover} onMouseLeave={onHover}>
              Get Started {hover ? <ArrowForward /> : <ArrowRight />}
            </HeroBtn>
          </HeroBtnWrapper>
        </TextContainer>
      </HeroContainer>
      <HeroImgContainer>
        <HeroImg src={PhoneImg} />
      </HeroImgContainer>
      <HeroSvg
        xmlns="http://www.w3.org/2000/svg"
        width="1085.183"
        height="1090.025"
        viewBox="0 0 1085.183 1090.025"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.262"
            y1="0.15"
            x2="0.794"
            y2="1.206"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#2cd483" />
            <stop offset="1" stop-color="#11be94" />
          </linearGradient>
        </defs>
        <path
          id="Path_3"
          data-name="Path 3"
          d="M1010.017-32.534S925.529,43.944,937.05,148.793,1031.78,322.72,998.5,418.934,880.725,676.74,906.327,807.494,1081.7,951.816,1161.072,950.582s322.591,35.772,405.8,66.61,320.031,45.64,389.158-19.736,0-1044.053,0-1044.053Z"
          transform="translate(-901.569 46.597)"
          fill="url(#linear-gradient)"
        />
      </HeroSvg>
    </HeroSection>
  )
}

export default Hero

const HeroSection = styled.div`
  /* visibility: hidden; */
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 1320px;
  padding: 0 40px;
  /* background: red; */
`

const TextContainer = styled.div`
  height: auto;
  z-index: 2;
`

const TextH1 = styled.h1`
  color: #2c2d32;
  font-size: 6.1rem;
  font-weight: 600;
  line-height: 5.5rem;
`

const TextH2 = styled.h2`
  color: #2cd483;
  font-size: 4.9rem;
  font-weight: 600;
`

const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`

const HeroBtn = styled(Link)`
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    #2cd483,
    #17cb94,
    #17cb94,
    #2cd483
  );
  background-size: 300% 100%;
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  white-space: nowrap;
  padding: 14px 48px;
  color: #2c2d32;
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-position: 100% 0;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    transform: translateY(-4px);
    color: #fff;
  }
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export const HeroSvg = styled.svg`
  position: absolute;
  top: -190px;
  left: 960px;
  width: 1085px;
`

export const HeroImgContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1320px;
  z-index: 1;
  display: flex;
  justify-content: center;
`

export const HeroImg = styled.img`
  width: 410px;
  display: flex;
  margin-left: 50vw;
  margin-top: 100px;
`
