import React from "react"
import styled from "styled-components"
import { BsCircleFill } from "react-icons/bs"
import { Link } from "gatsby"

const ScrollInd = () => {
  return (
    <DotContainer>
      <DotActive to="/">
        <BsCircleFill />
      </DotActive>
      <DotLink to="/">
        <BsCircleFill />
      </DotLink>
    </DotContainer>
  )
}

export default ScrollInd

const DotContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 100vh;
  width: 100%;
  max-width: 330px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const DotLink = styled(Link)`
  display: flex;
  cursor: pointer;
  color: #2c2d32;
  margin: 20px;
`

const DotActive = styled(Link)`
  display: flex;
  cursor: pointer;
  color: #2cd483;
  margin: 20px;
`
